:root {
    --mainColor: #4C9C2E;
    --grey: #76777B;
    --lightgrey: #F4F4F4;
    --warningYellow: #d4ae13a6;
}

.answersContainer{
    display: flex;
    align-items: center;
}

.warningIcon {
    color: var(--warningYellow);
    margin-left: 1rem;
    font-size: 1px;
}

.messageColor {
    color: var(--mainColor) !important;
}

.questionsColor {
    color: var(--grey) !important;
}

.MuiSelect-selectMenu {
    white-space: unset !important;
}

.MuiIconButton-root{
    padding: 5px 5px 5px 5px !important;
}

.MuiListItem-container{
    cursor: pointer !important;
}

.icon-container{
    display: flex;
}
.MuiTypography-colorPrimary{
    color:#4C9C2E !important;
}

/* TODO: Check container number */
.makeStyles-container-184{
    display: flex !important;
    justify-content: center !important;
}

@media only screen and (max-width: 600px) {
    .makeStyles-menuButton-2{
        color:#76777B !important;
    }
    .MuiInputBase-root{
        color:#4C9C2E!important;
    }
}

.formTitle{
    color: #4C9C2E !important;
}

.buttonVariant{
color: #76777B !important;
}

