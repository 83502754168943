.MuiAccordionSummary-root {
    padding: 0 !important;
}

@media only screen and (max-width: 959px) {
    .MuiAppBar-colorPrimary{
        background-color: #fff !important;
    }
    .MuiTypography-h6{
        color: #76777B !important;
    }
}

