:root {
    --mainColor: #4C9C2E;
    --grey: #76777B;
    --lightgrey: #F4F4F4;
}

.icon-hover:hover {
    color: var(--mainColor) !important;
}

.MuiListItemText-root > span {
    color: var(--grey) !important; 
}

.avatarBackground {
    background-color: var(--mainColor) !important;
}

.rightSection {
    border-left: 1px solid var(--grey);
    padding-left: 20px !important;
}

.iconDeleteOption {
    margin-right: 31px !important;
}

.centerVertical {
  display: flex !important;
  align-items: center !important;
}
